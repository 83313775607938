<template>
    <div class="im_evaluation">
        <template >
            <p>您对客服 {{ message.ext.receiverDisplayInfo.name }} 的服务满意吗?</p>
            <div class="evaluation_list">
                <div class="evaluation_item" v-for="(item, index) in evaluationData" :key="index">
                    <div class="grey-icon" v-if="currentEvaluationIndex != index">
                        <img :src="item.iconUrl.replace('.png', 'grey.png')" alt="">
                    </div>
                    <div class="icon" v-if="currentEvaluationIndex == index">
                        <img :src="item.iconUrl" alt="">
                    </div>
                    <!-- <i class="iconfont guoran-tongyichicun-write-15"></i> -->
                    <!-- guoran-tongyichicun-write-08 -->
                </div>
            </div>
            <div class="evaluation_reasons" v-if="currentEvaluation.type == 1">
                <div class="evaluation_reasons_list" v-if="currentEvaluation.reasons.length != 0">
                    <!-- {{ selectReasonList }} -->
                    <div class="evaluation_reasons_item" v-for="(item, index) in currentEvaluation.reasons" :key="index"
                        :class="{ 'active': selectReasonList.includes(item) }">
                        <span class="evaluation_reasons_item_title" style="margin-bottom: 0;">{{ item }}</span>
                    </div>
                </div>
            </div>
            <div class="evaluation_reasons_input"
                v-if="currentEvaluation.type == 1 && selectReasonList.includes('其他')">
                <div class="evaluation_reasons_input_item">
                    <el-input type="textarea" :rows="3" placeholder="请输入" v-model="reasonValue" :disabled="true">
                    </el-input>
                </div>
            </div>
            <div class="evaluation_questionnaire" v-if="currentEvaluation.type == 2">
                <span>
                    {{ currentEvaluation.questionnaireName }}
                </span>
                <i class="icon el-icon-arrow-right"></i>
            </div>
        </template>
        
    </div>
</template>

<script>
export default {
    props: ['message', 'companyConfig'],
    data() {
        return {
            // 选中的评价
            currentEvaluationIndex: null,
            currentEvaluation: {},
            selectReasonList: [],
            reasonValue: '',
            disabled: false
        }
    },
    computed: {
        evaluationData() {
            return JSON.parse(this.message.content)
        },
    },
    mounted() {
        this.initEvaluation()
        
    },
    watch: {
        message: {
            handler(value) {
                this.initEvaluation()
            },
            deep: true
        }
    },
    methods: {
        initEvaluation() {
            if (this.message.evaluationContent) {
                this.currentEvaluation = JSON.parse(JSON.stringify(this.message.evaluationContent))
                this.selectReasonList = this.currentEvaluation.receiverCheckReasons
                this.reasonValue = this.currentEvaluation.remark
                this.evaluationData.forEach((item, index) => {
                    if (item.name == this.currentEvaluation.name) {
                        this.currentEvaluationIndex = index
                    }
                })
            }
        },
    }
}
</script>

<style lang="less" scoped>
.im_evaluation {
    width: 100%;
    box-sizing: border-box;
    max-width: 70vw;
    min-width: 240px;
    >p {
        text-align: center;
    }
    .evaluation_list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        margin-top: 12px;

        .evaluation_item {
            img {
                width: 32px;
                height: 32px;
                margin: 0 8px;
                cursor: pointer;
            }

            i {
                font-size: 24px;
                // color: yellow;
            }
        }

    }

    .evaluation_reasons {
        margin-top: 12px;

        .evaluation_reasons_list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .evaluation_reasons_item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 26px;
                padding: 0 10px;
                margin: 4px 4px;
                border-radius: 16px;
                border: 1px solid #F4F8FC;
                cursor: pointer;
                font-size: 12px;
                box-sizing: border-box;
                line-height: 1;

                .evaluation_reasons_item_title {
                    // margin-left: 4px;
                }

                &.active {
                    span {
                        color: #366aff !important;
                    }

                    background: #F8FAFC;
                }
            }
        }
    }

    .evaluation_reasons_input {
        width: 100%;
        margin-top: 12px;

        .evaluation_reasons_input_item {
            width: 100%;

            textarea {
                width: 100%;
                height: 100px;
                padding: 8px;
                border: 1px solid #F4F8FC;
                border-radius: 4px;
                box-sizing: border-box;
                resize: none;
            }
        }
    }

    .evaluation_questionnaire {
        width: 100%;
        height: 32px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .can_not_view {
        display: flex;
        align-items: center;

        i {
            color: #09DDD5;
            margin-right: 8px;
        }
    }
}
</style>